import "./assets/SFPro/stylesheet.css"
import "./App.css";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import {Contact, Error, Home, PrivacyPolicy, Success, TermsAndConditions} from "./pages";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/error" element={<Error/>}/>
          <Route path="/success" element={<Success/>}/>
          <Route path="/privacy_policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms_and_conditions" element={<TermsAndConditions/>}/>
          <Route path="/contact" element={<Contact/>}/>
        </Routes>
      </BrowserRouter>
      <CookieConsent
        location="bottom"
        buttonText="Understand"
        cookieName="speechpastecookie"
        style={{ background: "#2B373B", position: 'fixed', bottom: 0, left: 0 }}
        buttonStyle={{
          background: '#2DABDE',
          borderRadius: 23,
          height: 30,
          minWidth: 110,
          color: 'white'
        }}
        expires={150}
      >
        This website uses cookies
      </CookieConsent>
    </div>
  );
}

export default App;
