import React, {useEffect} from "react";
import styled from "styled-components";

export const Error = () => {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = 'speechpaste://'
    }, 3000)
  }, [])

  return (
    <Root>
      <Content>
        <Title>Payment failed</Title>
        <Description>We are sorry, there were an error processing your payment. Please try again</Description>
      </Content>
      <a href="speechpaste://">Open the app</a>
    </Root>
  );
};

const Root = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    color: black;
  }
`

const Content = styled.div`
  padding: 24px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  max-width: 350px;
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
`

const Description = styled.p`
  
`
