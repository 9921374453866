import React from "react";
import styled from "styled-components";

export const PrivacyPolicy = () => {
  return (
    <Root>
      <Title style={{textAlign: 'center'}}>Privacy Policy</Title>
      <Divider/>
      <p style={{textAlign: 'center'}}>(hereinafter referred to as <strong>“the Policy”</strong>)</p>
      <Divider/>
      <p style={{textAlign: 'center'}}>
        <strong>of the AugmentedHeart BV</strong> (hereinafter referred to as <strong>“the Company”</strong>)
      </p>
      <Divider $big/>
      <i>Last update of the Policy: November 22, 2022</i>
      <Divider $big/>
      <p>
        This Policy describes the Company’s policies and procedures on thes collection,
        use and disclosure of the User’s information when the User uses the Online Service and tells the
        User about privacy rights and how the law protects the User.
        <Divider/>
        The Company uses the User’s Personal Data through Apple ID and Google Play to provide and improve the Online
        Service.
        By using the Online Service, the User agrees with the Company’s collection, processing,
        transferring and storage in compliance with this Policy.
        <Divider/>
        The Policy complies with Articles 12,13, and 14 of the GDPR.
      </p>
      <Divider $big/>
      <Title>I. INTERPRETATION AND DEFINITIONS</Title>
      <Divider/>
      <u><i>Interpretation</i></u>
      <Divider/>
      <p>
        The words in which the initial letter is capitalised have meanings defined under the following conditions.
        The following definitions shall have the same meaning regardless of whether they appear singular or plural.
      </p>
      <Divider/>
      <u><i>Definitions</i></u>
      <Divider/>
      <p>For the purposes of this Policy:</p>
      <Divider/>
      <p><strong>“The User”, “you”, “your”</strong> means any person who downloaded Mobile Application from AppStore or
        Google Play Market and uses the Company’s Online Service.</p>
      <Divider/>
      <p><strong>“Device”</strong> means any device that can access the Product, such as a computer, a cell phone, or a
        digital tablet.</p>
      <Divider/>
      <p><strong>“GDPR”</strong> means general data protection rules relating to the protection of natural persons about
        the processing of personal data and rules relating to the free movement of Personal Data.</p>
      <Divider/>
      <p><strong>“Mobile Application”</strong> means the Company’s application for the User’s mobile devices, which can
        be downloaded through the local mobile marketplace for each type of user.</p>
      <Divider/>
      <p><strong>“Product”</strong> means both Website and Company’s Mobile Application including performed services
        described by Terms and Conditions.</p>
      <Divider/>
      <p><strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person
        (“data subject”); an identifiable natural person is one who can be identified, directly or indirectly, in
        particular by reference to an identifier such as a name, location data, an online identifier, etc.</p>
      <Divider/>
      <p><strong>“Processing”</strong> means any operation or set of operations that are performed on personal data or
        sets of personal data, whether or not by automated means, such as collection, recording, organisation,
        structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission,
        dissemination or otherwise making available, alignment or combination, restriction, erasure, or destruction.</p>
      <Divider/>
      <p><strong>“The Company”</strong> means the AugmentedHeart, Amsterdam, The Netherlands.</p>
      <Divider/>
      <p><strong>“Usage Data”</strong> refers to data collected automatically, either generated using the Online Service
        or from the Online Service infrastructure (for example, the duration of a visit and application usage).</p>
      <Divider/>
      <p><strong>“Website”</strong> means our’s web platform for the User’s mobile devices or computers which can be
        used through a browser without downloading, under the name (domain) <a
          href="https://speechpaste.com/" target="_blank">https://speechpaste.com/</a></p>
      <Divider $big/>
      <Title>II. USAGE DATA</Title>
      <Divider/>
      <p>Usage Data is collected automatically when using the Product.</p>
      <Divider/>
      <p>Usage Data may include information, type of mobile device, pages that the User visits, the time and date of the
        User’s visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
      <Divider/>
      <p>When the User accesses the Product by or through any device, the Company may collect certain information
        automatically, including, but not limited to:</p>
      <Divider/>
      <List>
        <li>the type of device that is used;</li>
        <li>device unique ID;</li>
        <li>operating system;</li>
        <li>unique device identifiers and other diagnostic data.</li>
      </List>
      <Divider/>
      <p>
        Usage of contact form by the Website assists to collect username and all information given by the User.
        Account registration assists to collect username and password.
      </p>
      <Divider/>
      <p>Voice recordings are not stored; dictated texts are only stored on the user’s device.</p>
      <Divider $big/>
      <Title>III. TRACKING TECHNOLOGIES AND COOKIES</Title>
      <Divider/>
      <p>We use Cookies and similar tracking technologies to track the activity on the Website and store certain
        information. Tracking technologies are beacons, tags, and scripts to collect and track information and improve
        and analyse the Website. </p>
      <Divider/>
      <p>The technologies that we use may include (but are not limited):</p>
      <Divider/>
      <List>
        <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on your device. You can
          instruct the browser to refuse all Cookies or to indicate when Cookie is being sent. However, if you do not
          accept Cookies, you may not be able to use some parts of the Website.
        </li>
        <li><strong>Flash Cookies.</strong> Certain features of the Website may use locally stored objects (or Flash
          Cookies) to collect and store information about your preferences or activity on the Website. Flash Cookies are
          not managed by the same browser settings as those used for Browser Cookies.
        </li>
        <li><strong>Web Beacon. </strong> Certain sections of the Website emails may contain small electronic files
          known as Web Beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for
          example, to count guests who have visited those pages or opened an email and for other related website
          statistics (for example, recording the popularity of a certain section and verifying system and server
          integrity).
        </li>
      </List>
      <Divider/>
      <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your personal devices when you
        go offline, while Session Cookies are deleted as soon as you close the web browser.</p>
      <Divider/>
      <p>We use both Sessions and Persistent Cookies for the purposes set out below:</p>
      <Divider/>
      <strong>Necessary/Essential Cookies</strong>
      <Divider/>
      <p>Type: Session Cookies</p>
      <Divider/>
      <p>Administered by the Company</p>
      <Divider/>
      <p>Purpose: These Cookies are essential for providing you with services available through the Website and enabling
        you to use some of its features. They help to authenticate users and prevent fraudulent use of your accounts.
        Without these Cookies, the services you have asked for cannot be provided, and we only use these Cookies to
        provide you with those services.</p>
      <Divider $big/>
      <strong>Cookies Policy/Notice Acceptance Cookies</strong>
      <Divider/>
      <p>Type: Persistent Cookies</p>
      <Divider/>
      <p>Administered by the Company</p>
      <Divider/>
      <p>Purpose: These Cookies identify if you have accepted cookies on the Website.</p>
      <Divider $big/>
      <strong>Functionality Cookies</strong>
      <Divider/>
      <p>Type: Persistent Cookies</p>
      <Divider/>
      <p>Administered by the Company</p>
      <Divider/>
      <p>Purpose: These Cookies allow the Website to remember the choices you make when using the Website, such as
        remembering your login details or language preference. The purpose of these Cookies is to provide you with a
        more personal experience and avoid having to re-enter preferences every time you use the Website.</p>
      <Divider $big/>
      <strong>Advertising Cookies</strong>
      <Divider/>
      <p>Administered by the Company</p>
      <Divider/>
      <p>Purpose: Those cookies can be turned on and off by the Website to deliver our potential customers the best
        advertising experience. They do not contain personal information and are based on your actions over the
        Website.</p>
      <Divider $big/>
      <p>
        <strong>GOOGLE ANALYTICS:</strong> personal data and information obtained by way of such cookies concern the use
        that you make
        of the Website and will be transmitted from your browser to Google Inc., with registered office at 1600
        Amphitheatre Parkway, Mountain View, CA 94043, United States of America, and stored on Google’s servers.
        Google’s norms on privacy, which we kindly invite you to read, are available at the following URL:
        <a target="_blank"
           href="http://www.google.com/intl/it/privacy/privacy-policy.html"> http://www.google.com/intl/it/privacy/privacy-policy.html</a>.
      </p>
      <Divider/>
      <p>The information on personal data concerning Google Analytics services is available at the following URL: <a
        href="http://www.google.com/intl/en/analytics/privacyoverview.html"
        target="_blank">http://www.google.com/intl/en/analytics/privacyoverview.html</a>.</p>
      <Divider $big/>
      <p><strong>BROWSING DATA:</strong> computer systems and procedures responsible for the correct functioning of the
        Website automatically acquire, whilst operating, some Personal Data concerning your browsing history. For
        instance, within this category, we may find:</p>
      <Divider/>
      <ul style={{paddingLeft: 14}}>
        <li>IP addresses;</li>
        <Divider/>
        <li>Number of accesses to the Website;</li>
        <Divider/>
        <li>Visited pages;</li>
        <Divider/>
        <li>Date and time of access;</li>
        <Divider/>
        <li>Browser type;</li>
        <Divider/>
        <li>Adopted operating system.</li>
      </ul>
      <Divider $big/>
      <strong>Data voluntarily provided by you</strong>
      <p>Data freely and optionally provided by you via email to one of the email addresses indicated on the Website or
        in this Information may be acquired for purposes communicated to you from time to time. Besides email addresses
        required to answer you, additional Personal Data included within the same communication received by us may be
        processed. Personal Data collected as such will be retained and processed solely to preserve them and send
        correspondence and for no further purpose.</p>
      <Divider $big/>
      <Title>IV. THE PURPOSE OF USAGE THE USER’S PERSONAL DATA</Title>
      <Divider/>
      <p>The Company may use Personal Data for the following purposes:</p>
      <Divider/>
      <p><strong>To provide and maintain the Product services,</strong> including monitoring the usage of the Online
        Service.</p>
      <Divider/>
      <p><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the purchase
        contract for the services the User has purchased or of any other contract with the Company through the Product.
      </p>
      <Divider/>
      <p><strong>For business transfers:</strong> We may use your information to evaluate or conduct a merger,
        divestiture,
        restructuring, reorganization, dissolution, or another sale or transfer of some or all our assets, whether as a
        going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us
        about the Website and the Services users is among the assets transferred.</p>
      <Divider/>
      <p><strong>To manage the User’s requests:</strong> To attend to and manage the User’s requests to the Company.</p>
      <Divider/>
      <p><strong>For other purposes:</strong> the Company may use the User’s information for other purposes, such as
        data analysis, identifying usage trends, determining the effectiveness of the Company’s promotional campaigns,
        and evaluating and improving the Online Service, products, services, marketing, and the User’s experience.</p>
      <Divider/>
      <p>The Company may share the User’s Personal Data in the following situations:</p>
      <Divider/>
      <List>
        <li><strong>With service providers:</strong> the Company may share the User’s Personal with service providers to
          monitor and analyse the use of the Online Service, and to contact the User.
        </li>
        <li><strong>With the User’s Consent:</strong> the Company may disclose the User’s personal information for any
          other purpose with the User’s consent.
        </li>
      </List>
      <Divider $big/>
      <Title>V. RETENTION OF THE USER’S PERSONAL DATA</Title>
      <Divider/>
      <p>The Company will retain the User’s Personal Data only for as long as is necessary for the purposes set out in
        this Privacy Policy. </p>
      <Divider/>
      <p>The Company will retain and use the User’s Personal Data to the extent necessary to comply with the Company’s
        legal obligations (for example, if we are required to retain the User’s Personal Data to comply with applicable
        laws), resolve disputes, and enforce our legal agreements and policies.</p>
      <Divider/>
      <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
        shorter period, except when this Personal Data is used to strengthen the security or improve the functionality
        of the Online Service. The Company is legally obligated to retain this data for longer periods.</p>
      <Divider $big/>
      <strong>VI. TRANSFER OF THE USER’S PERSONAL DATA</strong>
      <p>The User’s information, including Personal Data, processing by the Company’s operating offices and in any other
        places where the parties involved in the processing are located. This information may be transferred to — and
        maintained on — computers outside of the User’s state, province, country, or other governmental jurisdiction
        where the data protection laws may differ from those of the User’s jurisdiction.</p>
      <Divider/>
      <p>The User’s consent to this Privacy Policy, followed by submission of such information, represents the User’s
        agreement to that transfer.</p>
      <Divider/>
      <p>The Company will take all steps reasonably necessary to ensure that the User’s Personal Data is treated
        securely and following this Privacy Policy, and no transfer of the User’s Personal Data will take place to an
        organization or a country unless there are adequate controls in place including the security of the User’s
        Personal Data and other personal information.</p>
      <Divider $big/>
      <Title>VII. DISCLOSURE OF THE USER’S PERSONAL DATA </Title>
      <Divider/>
      <strong>Business Transactions</strong>
      <Divider/>
      <p>If the Company is involved in a merger, acquisition or asset sale, the User’s Personal Data may be transferred.
        The Company will provide notice before the User’s Personal Data is transferred and becomes subject to a
        different Privacy Policy.</p>
      <Divider/>
      <strong>Law enforcement</strong>
      <Divider/>
      <p>Under certain circumstances, the Company may be required to disclose the User’s Personal Data if required to do
        so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
      <Divider/>
      <strong>Other legal requirements</strong>
      <Divider/>
      <p>The Company may disclose the User’s Personal Data in the good faith belief that such action is necessary
        to:</p>
      <Divider/>
      <List>
        <li>Comply with legal obligations.</li>
        <li>Protect and defend the rights or property of the Company.</li>
        <li>Prevent or investigate possible wrongdoing in connection with the Online Service.</li>
        <li>Protect the personal safety of the User of the Online Service or the public.</li>
        <li>Protect against legal liability.</li>
      </List>
      <Divider $big/>
      <Title>VIII. SECURITY OF THE USER’S PERSONAL DATA </Title>
      <Divider/>
      <p>The Company takes all reasonable steps to protect information that is received from the User from accidental or
        unlawful destruction, loss, alteration, and unauthorized disclosure or access. The Company has put in place
        appropriate physical, technical and administrative measures to safeguard and secure the User’s information, and
        the Company make use of privacy-enhancing technologies such as encryption. If you have any questions about the
        security of your personal information, you can contact us VIA email: <a
          href="mailto:info@speechpaste.com">info@speechpaste.com</a>.</p>
      <Divider/>
      <p>In any case, the Company respects and fully acknowledges the terms of the Health Insurance Portability and
        Accountability Act (HIPAA) in the case of using the Product or any of its parts for medical purposes, insurance
        purposes, or other purposes and under the conditions stipulated by HIPAA.</p>
      <Divider $big/>
      <Title>IX. LINKS TO OTHER WEBSITES</Title>
      <Divider/>
      <p>The Company’s Online Service may contain links to other websites not operated by the Company. If the User
        clicks on a third-party link, the User will be directed to that third party's website. The Company strongly
        advise the User to review the Privacy Policy of every site that the User visit.</p>
      <Divider/>
      <p>The Company has no control over and assumes no responsibility for the content, privacy policies or practices of
        any third-party sites or services.</p>
      <Divider $big/>
      <Title>X. CHANGES TO THIS PRIVACY POLICY</Title>
      <Divider/>
      <p>The Company may update the Policy from time to time. The Company will notify the User of any changes by posting
        the new Policy on this page.</p>
      <Divider/>
      <p>The Company has the right to update the Policy. The Company will let the User know via updating the “Last
        Updated” date at the top of this Policy.</p>
      <Divider/>
      <p>The User is advised to review this Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.</p>
      <Divider $big/>
      <Title>XI. GDPR NOTICE</Title>
      <Divider/>
      <p>The legal basis for processing the User’s Personal Data is Art. 6 sec. 1 a) b), f) Regulation (EU) 2016/679 of
        the European Parliament and of the Council of 27 April 2016 on the protection of individuals about the
        processing of personal data and the free movement of such data and repealing Directive 95/46 / MI Laws
        UE.L.2016.119.1) (GDPR), where the legitimate interest of the Company is related to providing the Online Service
        for the User.</p>
      <Divider/>
      <p>Personal Data will be processed for a period until an objection to data processing or termination is made, but
        no longer than 10 (ten) years.</p>
      <Divider/>
      <p>The User has the right to access, correct, delete, or restrict his or her Personal Data or to object to the
        processing, as well as the right to transfer the Personal Data and the right to complain to the supervisory
        authority.</p>
      <Divider/>
      <p>In the case of obtaining data and processing them based on Art. 6 sec. 1 a) GDPR – the User has the right to
        withdraw consent at any time, without prejudice to the lawfulness of the processing carried out based on consent
        to its withdrawal.</p>
      <Divider/>
      <p>To GDPR the Company is a data controller for the Personal Data collected from all categories of data subjects
        listed above, with the following exceptions: the Company is a data processor of the User logs, administrative
        user logs, and some account settings information. In addition, the Company is a data processor for any of the
        content provided by the User through the Online Services that transit. Where the Company is a data processor,
        the Company processes data on behalf of its the User under their data processing instructions.</p>
      <Divider/>
      <p>Data will be processed for a period until an objection to data processing or termination is made, but no longer
        than (ten) 10 years.</p>
      <Divider/>
      <p>The provision of data is voluntary, while the non-provision of data required by the type of processing may
        interfere with cooperation with the Company</p>
      <Divider $big/>
      <Title>XII. INFORMATION FOR CALIFORNIA RESIDENTS</Title>
      <Divider/>
      <p>This section provides additional disclosures required by the <a target="_blank"
                                                                         href="https://oag.ca.gov/privacy/ccpa">California
        Consumer Privacy Act</a> (or “CCPA”).</p>
      <Divider/>
      <p>Please see Chart “Categories of personal information we collect” below in this Policy for a list of the
        personal information the Company has the right to collect about California consumers in the last 12 (twelve)
        months, along with the Company's business and commercial purposes and categories of third parties with whom this
        information may be shared. For more details about the personal information the Company collect, including the
        categories of sources, please see the "Collecting and using Personal Data"</p>
      <Divider/>
      <strong>Categories of personal information we collect</strong>
      <Divider/>
      <p>Internet or other electronic network activity, such as browsing behaviour and information about the User’s
        usage and interactions with the Company’s Online Service. </p>
      <Divider/>
      <strong>Parties with whom the information may be shared</strong>
      <Divider/>
      <p>The third parties that provide services to the Company, such as those that assist us with customer support,
        subscription and order fulfilment, advertising measurement, communications and surveys, data analytics, fraud
        prevention, cloud storage, bug fix management and logging, and payment processing. The Company’s advertisers and
        marketing partners, such as partners that help determine the popularity of the content, deliver advertising and
        content targeted to the User’s interests and assist in better understanding the User’s online activity.</p>
      <Divider/>
      <p>Subject to certain limitations and exceptions, the CCPA provides California Users with the right to request to
        know more details about the categories and specific pieces of personal information, to delete their personal
        information, to opt out of any “sales” that may be occurring, and to not be discriminated against for exercising
        these rights.</p>
      <Divider/>
      <p>The Company do not “sell” the personal information we collect (and will not sell it in the future without
        providing a right to opt out). To opt out, the User can adjust his/her mobile device settings to limit ad
        tracking via the Mobile Application.</p>
      <Divider/>
      <p>California Users may make a rights request by emailing us at <a
        href="mailto:info@speechpaste.com">info@speechpaste.com</a>. The Company will verify the
        User’s request by asking the User to provide information that matches the information the Company has on file
        about the User. The User can also designate an authorized agent to exercise these rights on their behalf.
        Authorized agents should submit requests through the same channels, but the Company will require proof that the
        person is authorized to act on the Client’s behalf and may also still ask the User to verify his/her identity
        with the Company directly.</p>
      <Divider $big/>
      <Title>XIII. HIPAA NOTICE</Title>
      <Divider/>
      <p>In any case, the Company providing the Services and the User using the Product agree to apply the HIPAA in
        accordance with the provisions of the HIPAA,Terms and Conditions of the Product and fair business
        conditions.</p>
      <Divider/>
      <p>Violations of this clause are subject to liability in accordance with this Privacy Policy, Terms and Conditions
        of the Product, and applicable US law under HIPAA</p>
      <Divider $big/>
      <Title>XIV. DISPUTE RESOLUTION</Title>
      <Divider/>
      <p>If you have an unresolved privacy or data use concern that the Company has not addressed satisfactorily, please
        contact us via email <a href="mailto:info@speechpaste.com">info@speechpaste.com</a>. </p>
      <Divider $big/>
      <Title>XV. CONTACTS</Title>
      <Divider/>
      <p>If you have any questions about this Policy, you can contact us via email <a
        href="mailto:info@speechpaste.com">info@speechpaste.com</a>. </p>
    </Root>
  );
};

const Root = styled.div`
  padding: 24px 16px;
  line-height: 150%;
  font-size: 14px;
`

const Title = styled.h1`
  font-size: 14px;
  text-transform: uppercase;
  color: #292929;
`

const Divider = styled.span`
  display: block;
  height: ${({$big}) => $big ? '30px' : '10px'};
`

const List = styled.ul`
  padding-left: 0;
  list-style-type: none;

  li {
    padding-left: 32px;
    position: relative;

    &::before {
      content: '-';
      position: absolute;
      top: 0;
      left: 14px;
    }
  }
`
