import React, {useCallback, useRef, useState} from "react";
import {SESClient, SendEmailCommand} from "@aws-sdk/client-ses";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import styled from "styled-components";
import {Button} from "../components/ui";

export const Contact = () => {
  const [token, setToken] = useState()
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const textRef = useRef()
  const emailRef = useRef()

  const onVerify = useCallback(token => {
    setToken(token)
  }, [])

  const onSubmit = async e => {
    e.preventDefault()
    setRefreshReCaptcha(r => !r);
    const res = await fetch('https://speechpaste.com/api/v1/recaptcha/', {
      method: 'POST',
      body: JSON.stringify({captcha_value: token}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const {captcha} = await res.json()

    if (!captcha.success) {
      setButtonDisabled(true)
      if (captcha['error-codes'].includes('timeout-or-duplicate')) return alert('Request time is out, please refresh the page')
      return alert('You are a bot)')
    }

    try {
      const client = new SESClient({
        region: 'eu-central-1',
        credentials: {
          accessKeyId: 'AKIA365NVVZN2NYW6K4Z',
          secretAccessKey: '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'
        }
      })
      const command = new SendEmailCommand({
        Destination: {ToAddresses: ['info@augmentedheart.com']},
        Message: {
          Body: {Text: {Charset: 'UTF-8', Data: textRef.current.value}},
          Subject: {Charset: 'UTF-8', Data: 'Speech Paste Contact Message'}
        },
        ReplyToAddresses: [emailRef.current.value],
        Source: emailRef.current.value + ' <info@augmentedheart.com>'
      });
      await client.send(command)
      emailRef.current.value = ''
      textRef.current.value = ''
      alert('Message was successfully sent')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Le5wgIjAAAAAA-TV7SNx1KGlxmGrZer_1kW4CZm">
      <Root onSubmit={onSubmit}>
        <Title>Contact</Title>
        <Content>
          <EmailField ref={emailRef} type="email" required placeholder="Enter your email"/>
          <ContactField placeholder="Enter your message" ref={textRef} required/>
          <Button text="Send" disabled={buttonDisabled} customStyle={{margin: '16px 16px 16px auto'}}/>
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </Content>
      </Root>
    </GoogleReCaptchaProvider>
  );
};

const Root = styled.form`
  padding: 24px 16px;
`

const Title = styled.h1`
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
  color: #292929;
`

const Content = styled.div`
  border: 1px solid rgba(41, 41, 41, 0.5);
  border-radius: 8px;
  max-width: 700px;
  margin: 0 auto;
  overflow: hidden;
`

const ContactField = styled.textarea`
  resize: none;
  padding: 16px;
  width: 100%;
  display: block;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(41, 41, 41, 0.5);
  min-height: 150px;
`

const EmailField = styled.input`
  padding: 16px;
  display: block;
  border: none;
  width: 100%;
  outline: none;
  border-bottom: 1px solid rgba(41, 41, 41, 0.5);
`
