import {useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from 'react-router-dom'
import {io} from "socket.io-client";
import QRCode from "react-qr-code";
import styled from "styled-components";
import logo from "../assets/icon/logo.svg"
import {Button} from "../components/ui";

export const Home = () => {
  const [streaming, setStreaming] = useState({
    text: '',
    language: '',
    speciality: '',
    time: 0,
    status: ''
  })
  const [streamingTime, setStreamingTime] = useState(0)
  const [error, setError] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const navigate = useNavigate()
  const socket = useRef()
  const textRef = useRef(null)
  const time = useRef()

  const sessionId = useMemo(() => `speechpaste://sessionId=${Math.random().toString(36).slice(2, 13)}`, [])
  const recordingTime = useMemo(() => {
    const h = Math.floor(streamingTime / 3600).toString().padStart(2,'0')
    const m = Math.floor(streamingTime % 3600 / 60).toString().padStart(2,'0')
    const s = Math.floor(streamingTime % 60).toString().padStart(2,'0')

    return `${h}:${m}:${s}`
  }, [streamingTime])

  const onSave = () => {
    socket.current.emit('my_room_event', {
      data: {
        text: textRef.current.innerText,
        language: streaming.language,
        speciality: streaming.speciality,
        time: streaming.time,
        status: streaming.status,
        outsideChange: true
      },
      room: sessionId.split('sessionId=')[1]
    })
    setIsEditMode(false)
  }

  useEffect(() => {
    socket.current = io('https://ecg-backend-test-tcr2w7j4uq-uc.a.run.app')
    socket.current.emit('join', {room: sessionId.split('sessionId=')[1]})
    socket.current.on('my_response', ({data}) => {
      setStreaming({
        text: data.text,
        language: data.language,
        speciality: data.speciality,
        time: data.time || 0,
        status: data.status
      })
    })
    socket.current.on('disconnect', () => {
      setError(true)
    })

    return () => {
      socket.current.emit('leave', {room: sessionId})
      socket.current.emit('close_room', {room: sessionId})
      socket.current.disconnect()
    }
  }, [sessionId])

  useEffect(() => {
    if (streaming.status === 'recording') {
      time.current = setInterval(() => {
        setStreamingTime(time => time + 1)
      }, 1000)
    } else if (streaming.status === 'pause') {
      setStreamingTime(streaming.time)
      clearInterval(time.current)
    } else if (streaming.status === 'stop') {
      clearInterval(time.current)
      setStreamingTime(0)
      setStreaming({
        ...streaming,
        language: '',
        speciality: '',
        time: 0,
        status: 'stop'
      })
    }
  }, [streaming.status])

  return (
    <Root>
      <Logo src={logo}/>
      <Main>
        <div>
          <StreamHeader>
            <StreamParams>
              <span>{streaming.language}</span>
              <span>{streaming.speciality}</span>
            </StreamParams>
            <StreamingIndicator>
              <StreamingTime>{recordingTime}</StreamingTime>
              {streaming.status?.match(/recording|pause/) && <Live>LIVE</Live>}
            </StreamingIndicator>
          </StreamHeader>
          <StreamingBox>
            <StreamingBoxTop>
              <div
                ref={textRef}
                suppressContentEditableWarning
                contentEditable={!!streaming.status?.match(/stop|pause/)}
                style={{outline: 'none'}}
                onFocus={() => setIsEditMode(true)}
              >
                {streaming.text}
              </div>
              <Buttons>
                {!!streaming.status?.match(/stop|pause/) && (
                  <button
                    onClick={isEditMode ? onSave : () => textRef.current.focus()}
                  >
                    {isEditMode ? 'Save' : 'Edit text'}
                  </button>
                )}
                <button onClick={() => setStreaming(state => ({...state, text: ''}))}>Clean text</button>
                <button onClick={() => navigator.clipboard.writeText(streaming.text)}>Copy text</button>
                {/*<button>Previous</button>*/}
                {/*<button>Next</button>*/}
              </Buttons>
            </StreamingBoxTop>
            {/*<StreamingBoxBottom>*/}
            {/*  <Button text="Cancel" danger/>*/}
            {/*  <Button text="Done"/>*/}
            {/*</StreamingBoxBottom>*/}
          </StreamingBox>
          {error && <Error>You are disconnected. To continue, reload the page and rescan</Error>}
        </div>
        <div>
          <QRTitle>Scan this QR Code</QRTitle>
          <Footer>
            <div>
              <div>
                <Button text="Privacy policy" onClick={() => navigate('/privacy_policy')}/>
                <Button text="Terms and Conditions" onClick={() => navigate('/terms_and_conditions')}/>
                <Button text="Contact Us" onClick={() => navigate('/contact')}/>
              </div>
            </div>
            <div>
              <QRCodeWrap><QRCode size={166} value={sessionId} /></QRCodeWrap>
              <QRDescription>Smartphone will start app and work as Microphone</QRDescription>
            </div>
          </Footer>
        </div>
      </Main>
    </Root>
  );
}

const Root = styled.div`
  padding: clamp(24px, 4vw, 45px) 16px;
  color: #292929
`

const Logo = styled.img`
  display: block;
  height: clamp(50px, 8vw, 90px);
  margin: 0 auto 24px auto;
`

const Main = styled.div`
  max-width: 840px;
  margin: 0 auto;
  display: grid;
  gap: 24px;
`

const StreamHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const StreamParams = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
  
  span {
    font-size: 14px;
    font-weight: 600;
  }
`

const StreamingIndicator = styled.div`
  display: flex;
  align-items: center;
`

const StreamingTime = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-right: 15px;
`

const Live = styled.div`
  background: #EA3323;
  border-radius: 6px;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 12px;
  color: white;
`

const StreamingBox = styled.div`
  border: 1px solid rgba(41, 41, 41, 0.5);
  border-radius: 8px;
`

const StreamingBoxTop = styled.div`
  padding: 20px 24px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
`

const StreamingBoxBottom = styled.div`
  padding: 14px 24px;
  border-top: 1px solid rgba(41, 41, 41, 0.5);
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
  justify-content: end;
`

const Buttons = styled.div`
  margin: auto -5px -5px 0;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: end;
  gap: 30px;

  button {
    border: none;
    background: none;
    padding: 5px;
    cursor: pointer;
    font-size: 14px;
    color: #8A8A8E;
    font-weight: 600;

    &:hover {
      color: #4e4e4f;
    }

    &:active {
      color: #b1b1b7;
    }
  }
`

const QRTitle = styled.h3`
  font-weight: 700;
  font-size: clamp(18px, 3vw, 28px);
  text-align: center;
  color: #292929;
  margin-bottom: 20px;
`

const QRCodeWrap = styled.div`
  background: #F5F5F5;
  border-radius: 1px;
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`

const QRDescription = styled.p`
  font-size: 12px;
  text-align: center;
  color: black;
  max-width: 140px;
  margin: 0 auto;
`

const Error = styled.p`
  font-size: 14px;
  color: #f44336;
`

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
  
  @media(max-width: 680px) {
    grid-template-columns: auto;
  }
  
  div:first-child {
    display: flex;
    align-items: flex-end;

    @media(max-width: 680px) {
      justify-content: center;
    }
    
    div {
      display: grid;
      grid-template-columns: 200px 250px;
      gap: 24px;

      @media(max-width: 680px) {
        grid-template-columns: auto;
      }
    }
  }
`
