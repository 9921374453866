import React from "react";
import styled, {css} from "styled-components";

export const Button = ({text, height, width, fontSize, danger, customStyle, disabled, ...props}) => {
  return (
    <Root {...props} disabled={disabled} style={{height, width, fontSize, ...customStyle}} $danger={danger} $disabled={disabled}>
      {text}
    </Root>
  );
};

const Root = styled.button`
  background: #2DABDE;
  border-radius: 23px;
  height: 46px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: white;
  border: none;
  padding: 0 24px;
  transition: .15s;
  cursor: pointer;
  
  &:hover {
    background: #4FBAAE;
  }
  
  ${({$danger}) => $danger && css`
    background: transparent;
    color: #EA3323;
    border: 1px solid #EA3323;

    &:hover {
      color: white;
      background: #EA3323;
    }
  `}

  ${({$disabled}) => $disabled && css`
    background: gray;

    &:hover {
      background: gray;
      cursor: not-allowed;
    }
  `}
`
