import React from "react";
import styled from "styled-components";

export const TermsAndConditions = () => {
  return (
    <Root>
      <Title style={{textDecoration: 'underline', textAlign: 'center'}}>
        PLEASE READ THE FOLLOWING CAREFULLY AS IT AFFECTS THE USER’S LEGAL RIGHTS.
      </Title>
      <Divider/>
      <p>Last updated November 22, 2022]</p>
      <Divider/>
      <p><strong>This Terms and Conditions</strong> (hereinafter – <strong>“Terms”</strong>), along with AugmentedHeart
        BV (hereinafter –
        “AugmentedHeart”) Privacy Policy, governs the User`s use of the Product. </p>
      <Divider/>
      <strong>For this Terms:</strong>
      <Divider/>
      <p>(i) <strong>“Services”</strong> means the scope of functionality available within the Product to order and
        purchase due to these Terms.</p>
      <Divider/>
      <p>(ii) <strong>“Officers”</strong> means our’s officers, directors, employees, consultants, affiliates,
        subsidiaries, and agents.</p>
      <Divider/>
      <p>(iii) <strong>“Company”, “we”, “our”, and “us”</strong> means the company that is an owner of this Website and
        Mobile
        Application. The name of the company is AugmentedHeart BV, with its principal place of business at Amsterdam,
        The Netherlands.</p>
      <Divider/>
      <p>(iv) <strong>“Services”</strong> means transforming your voice into written words via Mobile Application.</p>
      <Divider/>
      <p>(v) <strong>“User”, “you”,</strong> and <strong>“your”</strong> means any individual or legal entity who is
        using our Product.</p>
      <Divider/>
      <p>(vi) <strong>“Website”</strong> means our’s web platform for the User’s mobile devices or computers which can
        be used through a
        browser without downloading, under the name (domain) <a target="_blank"
                                                                href="https://speechpaste.com">https://speechpaste.com</a> .
      </p>
      <Divider/>
      <p>(vii) <strong>“Mobile Application”</strong> means the Company’s application for the User’s mobile devices,
        which can be
        downloaded through the local mobile marketplace for each type of user. </p>
      <Divider/>
      <p>(viii) <strong>“Product”</strong> means both Website and Company’s Mobile Application.</p>
      <Divider $big/>
      <Title>1. ELIGIBILITY</Title>
      <Divider/>
      <p>1.1. By agreeing to these Terms, you represent and warrant to us: </p>
      <Divider/>
      <p>(i) that your age is at least eighteen (18) years old; before determining the age, the Mobile Application usage
        is regulated by <a target="_blank" href="https://www.apple.com/legal/internet-services/itunes/">Apple App Store
          age restrictions provisions</a> and <a target="_blank"
                                                 href="https://play.google.com/intl/en_uk/about/play-terms/"> Google
          Play Market provisions</a>; </p>
      <Divider/>
      <p>(ii) that you have not previously been suspended or removed from the Mobile Application and Online Service;</p>
      <Divider/>
      <p>(iii) that your use of the Mobile Application and Online Service complies with applicable laws and regulations
        and</p>
      <Divider/>
      <p>(iv) User authorisation is arranged via AppStore, Apple ID and/or Google Play Market account.</p>
      <Divider $big/>
      <Title>2. LICENCE GRANT TO US</Title>
      <Divider/>
      <p>2.1. By providing information (hereinafter – “Files”) through the Product or via other types of electronic
        communications, you approve our processing of your Files by the way described in this Terms and Private
        Policy.</p>
      <Divider/>
      <p>2.1.1. These Files can automatically be downloaded from your end by the Website’s Cookies within using
        Website.</p>
      <Divider/>
      <p>2.2. We guarantee that all Files will be used only for data processing purposes by the Product. Any third party
        will not have any access to your Files without your additional direct permission.</p>
      <Divider $big/>
      <Title>3. INFRINGEMENT AND ABUSE</Title>
      <Divider/>
      <p>3.1. We are not responsible for the accuracy of the Product work on your end and the Files on the Website after
        using the Product.</p>
      <Divider/>
      <p>3.2. We are not responsible for not right using the Product due to any damage to you and/or any other third
        parties.</p>
      <Divider/>
      <p>3.3. We do not check the User’s ID and the Files for the User’s ownership based on the peculiarities of data
        processing by the Product.</p>
      <Divider $big/>
      <Title>4. TERMINATION OF USE, DISCONTINUATION AND MODIFICATION/UPGRADING OF THE PRODUCT</Title>
      <Divider/>
      <p>4.1. We have the right to modify/upgrade or discontinue the Product at any time (including, without limitation,
        by limiting or discontinuing certain features of the Product) without notice to you.</p>
      <Divider/>
      <p>4.2. We do not have any liability whatsoever on account of any change to the Product or any suspension or
        termination of your access to or use of the Product.</p>
      <Divider/>
      <p>4.3. We have the right to restrict your access, immediately without previous notification and in any way we
        deem appropriate, to the Product if we detect any violations from your end. In this case, you do not have the
        right to refund your funds or restore access.</p>
      <Divider/>
      <p>4.4. We have the right to restrict your access, immediately without previous notification, to the Product if
        any unapproved modifications to the Product are detected from your side.</p>
      <Divider $big/>
      <Title>5. THIRD-PARTY’S LINKS</Title>
      <Divider/>
      <p>5.1. The Product may contain links to third-party web sources. Such linked websites are not under our control,
        and we are not responsible for their content.</p>
      <Divider/>
      <p>5.2. We have the right to cooperate with third parties and provide your personal data to third parties without
        written permission from your side who used/clicked the third party’s link on the Website.</p>
      <Divider $big/>
      <Title>6. OWNERSHIP; PROPRIETARY RIGHTS OF THE COMPANY</Title>
      <Divider/>
      <p>6.1. The visual interfaces, graphics, design, compilation, information, data, computer code (including source
        code or object code), products, software, services, and all other elements or files of the Product (hereinafter
        – <strong>“the Materials”</strong>) provided by us are protected by all relevant intellectual property and
        proprietary rights and
        applicable laws. </p>
      <Divider/>
      <p>6.2. All Materials contained within the Product are the property of the Company or its third-party
        licensors.</p>
      <Divider/>
      <p>6.3. If the Product contains any materials, interfaces, logos, designs, products, or something else that is not
        the intellectual property of the Company and the Company doesn’t have licenses for using it, the licensor has
        the right to request to delete its intellectual property from the Website according to the procedures defined in
        the <a target="_blank" href="https://www.govinfo.gov/content/pkg/PLAW-105publ304/pdf/PLAW-105publ304.pdf">Digital
          Millennium Copyright Act</a> (hereinafter – “DMCA”) and/or <a target="_blank"
                                                                        href="https://eur-lex.europa.eu/eli/dir/2019/790/oj">Directive
          (EU) 2019/790 of the European
          Parliament and of the Council of 17 April 2019 on copyright and related rights in the Digital Single Market
          and
          amending Directives 96/9/EC</a> (hereinafter – <strong>“EUCD”</strong>).</p>
      <Divider/>
      <p>6.4. We do not claim to be the owners or representatives of the trademarks, brands and intellectual properties
        of other sides and remain the property of the original copyright owners.</p>
      <Divider/>
      <p>6.5. Except as expressly authorized by us in writing, you may not make use of the Materials. </p>
      <Divider/>
      <p>6.6. We reserve all rights to the Materials not granted expressly in this Terms.</p>
      <Divider $big/>
      <Title>7. PROHIBITED USES</Title>
      <Divider/>
      <p>7.1. As a condition of your use of the Product, you will not use the Product for any purpose that is unlawful
        or prohibited by this Terms. You may not use the Product in any manner that could damage, disable, overburden,
        disrupt or impair any of our servers or APIs, any networks connected to any of our’s servers or APIs, or that
        could interfere with any other party's use and enjoyment of the Product. </p>
      <Divider/>
      <p>7.2. You may not transmit any viruses, worms, defects, trojan horses, or any items of a destructive nature
        using the Product. </p>
      <Divider/>
      <p>7.3. You may not exceed or circumvent, or try to exceed or circumvent, limitations on the Product, including on
        any API calls, or otherwise use the Product in a manner that violates any of our documentation or user
        manuals. </p>
      <Divider/>
      <p>7.4. You and/or any other third parties may not attempt to gain unauthorized access to any websites, other
        accounts, computer systems, or networks connected to any of our servers or the Product through hacking, password
        mining, or any other means. </p>
      <Divider/>
      <p>7.5. You and/or any other third parties may not obtain or attempt to obtain any materials or information
        through any means not intentionally made available through the Product.</p>
      <Divider/>
      <p>7.6. You and/or any other third parties may not use the Product in any way that violates any applicable state,
        local, or international law or regulation (including, without limitation, any laws regarding the export of data
        or software to and from the European Union, the USA, or other countries and intellectual property rights
        according to the DMCA and EUCD.</p>
      <Divider $big/>
      <Title>8. INDEMNITY</Title>
      <p>You agree to be responsible for using the Product and you agree to defend, indemnify, and hold harmless the
        Company and its Officers from and against any claims, liabilities, damages, losses, and expenses, including
        reasonable attorneys' fees and costs, arising out of or in any way connected with </p>
      <Divider/>
      <p>(i) your access to, use of, or alleged use of the Product; </p>
      <Divider/>
      <p>(ii) your violation of these Terms or any representation, warranty, or agreements referenced herein or any
        applicable law or regulation; </p>
      <Divider/>
      <p>(iii) your violation of any third-party right, including without limitation any intellectual property right,
        publicity, confidentiality, property, or privacy right; or </p>
      <Divider/>
      <p>(iv) any disputes or issues between you and any third party. We reserve the right, at our own expense, to
        assume the exclusive defence and control of any matter otherwise subject to indemnification by you (and without
        limiting your indemnification obligations concerning such matter), and in such case, you agree to cooperate with
        our defence of such claim.</p>
      <Divider $big/>
      <Title>9. DISCLAIMERS, NO WARRANTIES</Title>
      <Divider/>
      <p>9.1. The Product is made available to you on an “as is” and “as available” basis, with the express
        understanding that we have no obligation to monitor, control, or vet the content or data appearing on the
        Website.</p>
      <Divider/>
      <p>9.2. You use the Product at your discretion and risk, and you take full responsibility for your safety and the
        safety of your payment information.</p>
      <Divider/>
      <p>9.3. We make no claims or promises about the quality, accuracy, or reliability of the Product and expressly
        disclaim all warranties, whether express or implied, including implied warranties of merchantability, fitness
        for a particular purpose, and non-infringement.</p>
      <Divider $big/>
      <Title>10. LIMITATION OF LIABILITY</Title>
      <Divider/>
      <p>10.1. In any case, we will not be liable to you or any third party for any direct, indirect, incidental,
        special, consequential, or punitive damages arising out of or relating to your access to or use of, or your
        inability to access or use, the Product or any materials or the Services, whether based on warranty, contract,
        tort (including negligence), statute, or any other legal theory, whether or not we have been informed about of
        the possibility of such damage.</p>
      <Divider/>
      <p>10.2. We, in any case, are not responsible for services provided to you by the third party who promoted on the
        Product.</p>
      <Divider/>
      <p>10.3. All risks related to your information, account, or accounts on other sources if you shared your data are
        not a part of our responsibility.</p>
      <Divider/>
      <p>10.4. You accept responsibility for using the Product and all results of its usage.</p>
      <Divider $big/>
      <Title>11. GOVERNING LAW</Title>
      <Divider/>
      <p>These Terms will be governed by the laws of the Netherlands, without regard to conflict of law principles. To
        the extent that any lawsuit or court proceeding is permitted hereunder, you and we agree to submit to the
        personal and exclusive jurisdiction of the state courts in the Netherlands to litigate all such disputes.</p>
      <Divider $big/>
      <Title>12. CHANGES TO THESE TERMS</Title>
      <Divider/>
      <p>12.1. We reserve the right to make modifications to this Terms and Private Policy at any time. Revised versions
        of these Terms will be posted within the Product. Unless otherwise specified, any modifications to the Terms
        will take effect the day they are posted within the Product. </p>
      <Divider/>
      <p>12.1.1. We are not obliged to inform you about changes in these Terms.</p>
      <Divider/>
      <p>12.2. If you do not agree with the revised Terms, your sole and exclusive remedy will be to discontinue your
        use of the Product.</p>
      <Divider $big/>
      <Title>13. PRICES</Title>
      <Divider/>
      <p>13.1. Prices for our products are subject to change without notice.</p>
      <Divider/>
      <p>13.2. We shall not be liable to you or any third party for any modification, price change, suspension or
        discontinuance of the Services.</p>
      <Divider/>
      <p>13.3. We provide access to pay via third-party service Stripe.</p>
      <Divider/>
      <p>13.4. First 30 minutes of Product usage are free of charge.</p>
      <Divider $big/>
      <Title>14. SERVICES</Title>
      <Divider/>
      <p>14.1. By using the Product, you use the compatible services of the Website and the Mobile Application.</p>
      <Divider/>
      <p>14.2. The product allows you to transform voice words into text. To perform this, you need to download the
        Mobile Application and register in it in accordance with the requirements of the Mobile Application.</p>
      <Divider/>
      <p>14.3. After that, you should go to the Website and scan the QR code using the functionality of the Mobile
        Application. By clicking on the corresponding button within the Mobile Application, you can start speaking
        orally. The artificial intelligence of the Mobile Application will allow you to recognize your words and
        transform them into text on the Website. You can use this text as you wish, in particular, copy it to any other
        device. </p>
      <Divider/>
      <p>14.4. The product is paid for. The first 30 minutes are offered to the User free of charge. The following
        minutes are sold in packages according to the current prices indicated on the resources of the Product.</p>
      <Divider/>
      <p>14.5. The possibility of refunding the User's money is provided. For this purpose, you need to contact the
        owner of the Product using the contact form and provide all requested information.</p>
      <Divider $big/>
      <Title>15. GENERAL</Title>
      <Divider/>
      <p>15.1. These Terms, together with the Privacy Policy and any other agreements expressly incorporated by
        reference herein, constitute the entire and exclusive understanding and agreement between you and us regarding
        your use of and access to the Product and the Services. </p>
      <Divider/>
      <p>15.2. Use of section headers in this Terms is for convenience only and will not have any impact on the
        interpretation of provisions. </p>
      <Divider/>
      <p>15.3. You have no rights to assign or transfer these Terms or your rights hereunder, in whole or in part, by
        operation of law or otherwise, without our prior written consent. </p>
      <Divider/>
      <p>15.4. We have the right to assign these Terms at any time without notice. </p>
      <Divider/>
      <p>15.5. The failure to require performance of any provision will not affect our right to require performance at
        any time thereafter, nor will a waiver of any breach or default of this Terms or any provision of this Terms
        constitute a waiver of any subsequent breach or default or a waiver of the provision itself. </p>
      <Divider/>
      <p>15.6. If any part of this Terms is held to be invalid or unenforceable, the unenforceable part will be given
        effect to the greatest extent possible, and the remaining parts will remain in full force and effect. </p>
      <Divider/>
      <p>15.7. You acknowledge that the Product is not intended to be technology protection measures that will help you
        to comply with the GDPR and CCPA. </p>
      <Divider/>
      <p>15.8. If the Product is used to perform direct or related services to the Health Insurance Portability and
        Accountability Act of 1996 (HIPAA), the Company covenants that it will appropriately safeguard Protected Health
        Information (defined in 45 CFR 160.103), and agrees that it is subject to, and shall comply with, the provisions
        of 45 CFR 164 Subpart E regarding use and disclosure of Protected Health Information.</p>
      <Divider $big/>
      <Title>16. COMMUNICATION AND NOTIFICATIONS</Title>
      <Divider/>
      <p>16.1. The entire communication with us is electronic. Every time you use a contact form or visit our Product
        and use our Services, you will be communicating with us. </p>
      <Divider/>
      <p>16.2. You hereby consent to receive communications from the Company. </p>
      <Divider/>
      <p>16.3. You also agree that all electronic notices, disclosures, agreements, and other communications we provide
        meet the legal requirements that such communications be in writing.</p>
      <Divider/>
      <p>16.4. You agree to receive any notifications through any part of the Product to your Device.</p>
      <Divider/>
      <p>16.5. Through the contact form at the Website, you can contact us to receive support for the Services that you
        are using already. </p>
      <Divider $big/>
      <Title>17. MARKETS TERMS</Title>
      <Divider/>
      <p>17.1. Concerning the Website part of the Product we grant you a not-limited, non-exclusive, non-transferable,
        non-sublicensable license to use the Website and the Services. We reserve all rights in and to the Website and
        Services not expressly granted to you under this Terms. Except as expressly permitted in this Terms, you may
        not: (i) copy, modify or create derivative works based on the Website; (ii) reverse engineer, decompile or
        disassemble the Website and Services</p>
      <Divider/>
      <p>17.2. Concerning the Mobile Application part of the Product we grant you not limited, non-exclusive,
        non-transferable, non-sublicensable license to download and install a copy of the Mobile Application on a mobile
        device or computer that the User owns or controls and to run such copy of the Mobile Application solely for your
        own personal non-commercial purposes. The Company reserves all rights in and to the Mobile Application not
        expressly granted to you under these Terms. The User may not copy the Mobile Application except for making a
        reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you
        may not: (i) copy, modify or create derivative works based on the Mobile Application; (ii) distribute, transfer,
        sublicense, lease, lend or rent the Mobile Application to any third party; (iii) reverse engineer, decompile or
        disassemble the Mobile Application; or (iv) make the functionality of the Mobile Application available to
        multiple users through any means.</p>
      <Divider/>
      <p>16.2. Additional term for AppStore</p>
      <Divider/>
      <p>16.2.1. If the User accessed or downloaded the Mobile Application from the Apple Store, then the User agrees to
        use the App only: (i) on an Apple-branded product or device that runs iOS (Apple’s proprietary operating system
        software); and (ii) as permitted by the “Usage Rules” set forth in the Apple Store Terms of Service.</p>
      <Divider $big/>
      <Title>18. OTHER CONDITIONS</Title>
      <Divider/>
      <p>18.1. If you do not agree to these Terms, you must not access or use the Product and the Services.</p>
      <Divider/>
      <p>18.2. We provide Services to you, which are subject to the conditions stated in these Terms. You follow all
        these conditions and rules every time you visit/use the Product using its Service.</p>
    </Root>
  );
};

const Root = styled.div`
  padding: 24px 16px;
  line-height: 150%;
  font-size: 14px;
`

const Title = styled.h1`
  font-size: 14px;
  text-transform: uppercase;
  color: #292929;
`

const Divider = styled.span`
  display: block;
  height: ${({$big}) => $big ? '30px' : '10px'};
`
